/**
 * Automatically close areas of the website when you click somewhere else.
 *
 * `[data-auto-close]` will be closed if you click anywhere outside.
 *
 * - Add `layout-closed` class entry.
 * - Remove `layout-open` class entry.
 * - Remove `open` attribute, if it is a HTMLDetailsElement.
 *
 * @example
 * ```html
 * <details data-auto-close>
 *   <summary>Pop it like it's hot</summary>
 *   When the pimp's in the crib ma
 *   Drop it like it's hot
 * </details>
 * ```
 *
 * @module [data-auto-close]
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.84.0
 */

import {defineRegistryAttribute} from '@acng/frontend-stargazer';
import {ATTR_OPEN, HTMLDetailsElement, TAGNAME_DETAILS} from '@acng/frontend-bounty/dom/type.js';
import {onClickElement} from '@acng/frontend-bounty/dom/event.js';
import {getParentElement, queryAll} from '@acng/frontend-bounty/dom/query.js';
import {add, clear, createSet, has} from '@acng/frontend-bounty/collection.js';
import {Node, document, isInstanceOf, setTimeout} from '@acng/frontend-bounty';

import {inject} from 'acng/core/service/ng.js';
import {STYLE_CLOSED, swapClass} from '../service/style.js';

const MODULE = 'layout/attribute/auto-close';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @type {Set<Element>}
 */
const skipElements = createSet();

/**
 * @param {?Node} node
 */
export const skipPath = (node) => {
  for (; node; node = getParentElement(node)) {
    add(skipElements, node);
  }
};

defineRegistryAttribute('data-auto-close', (name) => {
  DEBUG: if (VERBOSE) console.info(MODULE, 'setup', TAGNAME_DETAILS);

  /**
   * @param {unknown} skip
   */
  const autoCloseAll = (skip = null) => {
    if (isInstanceOf(skip, Node)) {
      skipPath(skip);
    }

    const includeElements = queryAll(document, `[${name}]`);
    DEBUG: if (VERBOSE) console.debug(MODULE, {includeElements, skipElements: [...skipElements]});

    for (const details of includeElements) {
      if (!has(skipElements, details)) {
        DEBUG: if (VERBOSE || details.hasAttribute('debug')) console.info(MODULE, 'close', {details});

        if (isInstanceOf(details, HTMLDetailsElement)) {
          details[ATTR_OPEN] = false;
        }

        swapClass(details, STYLE_CLOSED, true);
      }
    }

    setTimeout(() => clear(skipElements));
  };

  onClickElement(document, autoCloseAll);
  inject('$rootScope').$on('$locationChangeSuccess', autoCloseAll);
});
