import angular from 'angular';
import {popup} from '@acng/frontend-discovery';

import {hasFeature, isMobile} from 'acng/core/service/env.js';
import {getLocale} from 'acng/locale/model/locale.js';
import {LivecamSession} from '../model/session.js';
import {setGlobalLivecamSession} from '../context/session.js';
import {publishUserMessage} from 'acng/messenger/service/event-bus.js';
import {ctxSentMessage} from 'acng/messenger/context/message.js';
import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {Message} from 'acng/messenger/model/message.js';
import {now} from '@acng/frontend-bounty/timing/now.js';

ctxSentMessage[CTX_SUBSCRIBE_TAGNAME]('.lcs-consumer');

angular.module('livecam').directive('onswLivecamShow', [
  'User',
  '$compile',
  '$injector',
  '$translate',
  'payment',
  '$location',
  'onsOverlay',
  'Livecam',
  '$rootScope',
  /**
   * @param {import('acng/userPool/factory/user').User} User
   * @param {angular.ICompileService} $compile
   * @param {angular.auto.IInjectorService} $injector
   * @param {angular.translate.ITranslateService} $translate
   * @param {import('acng/payment/factory/payment').Payment} payment
   * @param {angular.ILocationService} $location
   * @param {import('acng/core/service/overlay.js').OverlayConstructor} onsOverlay
   * @param {import('acng/livecam/factory/livecam').LivecamConstructor} Livecam
   * @param {import('acng/zz-app.js').RootScope} $rootScope
   */
  function (
    User,
    $compile,
    $injector,
    $translate,
    payment,
    $location,
    onsOverlay,
    Livecam,
    $rootScope
  ) {
    return {
      scope: {token: '@'},
      template: `<div class="box" id="livecamOverlay">
                    <div onsw-livecam-close></div>
                    <div id="livecamPlayer" ng-style="{height: playerHeight()}"></div>
                    <div onsw-hook hookname="livecamTools" hook-data="token"></div>
                </div>`,
      link: link,
    };

    /**
     * @type {angular.IDirectiveLinkFn<angular.IScope & {
     *   token: string;
     *   showButtons: boolean;
     *   showControls: boolean;
     *   playerHeight: () => string;
     *   nickname: string;
     *   session: LivecamSession;
     * }>}
     */
    function link(scope, element) {
      element.addClass('lcs-consumer');
      const player = element.find('#livecamPlayer');
      const tools = element.find('[hookname="livecamTools"]');
      scope.playerHeight = function () {
        return `calc(100% - ${tools.outerHeight()}px)`;
      };

      let as = null;
      if ($injector.has('Actionslider')) {
        as = $injector.get('Actionslider');
        as.hide();
      }

      scope.showButtons = false;
      scope.showControls = true;

      scope.$watch('token', function (token) {
        stop();
        const session = LivecamSession.get(token);
        if (session == undefined) {
          $translate('livecam.invalidToken').then((text) => {
            popup().warn(text);
            history.back();
          });
          return;
        }

        scope.showButtons = false;
        scope.nickname = session.amateur.nickname;
        scope.session = session;
        start();
      });

      /**
       * @param {number} status
       */
      function onExit(status) {
        let location;
        let message;
        /**
         * @type {{type: string, class?: string[]} | false}
         */
        let openPayment = false;

        switch (status) {
          case 9:
            break;
          case 7:
            location = `/sedcard/${scope.session.amateur.nickname}`;
            if (!User.level && scope.session.type == 'free') {
              openPayment = {
                class: ['free-cam'],
                type: 'livecam.freeshow.limitReached',
              };
            } else {
              openPayment = {
                type: 'livecam.sessionExpired',
              };
            }
            break;
          case 3:
            location = `/sedcard/${scope.session.amateur.nickname}`;
            message = true;
            break;
          default:
            location = LivecamSession.prevPath || '/livecams/online';
            message = true;
        }

        // Disable Regard shop after livecam show on all whitelabels where the regard feature is disabled
        if (
          scope.session.amateur.features.includes('chat') &&
          Date.now() - scope.session.start_at >= 1000 * 300 &&
          !openPayment &&
          hasFeature('regard')
        ) {
          onsOverlay
            .create('livecamRegardOverlay', {amateur: scope.session.amateur})
            .setPositionMain()
            .open();
        }

        if (message) {
          $translate('livecam.events.status' + status, {sender: scope.session.amateur.getNickname()}).then(
            (text) => popup().warn(text)
          ); // TODO turbo boost
        }

        if (location) {
          $rootScope.$applyAsync(() => $location.url(location));
        }

        if (openPayment) {
          payment.overlay(
            openPayment.type,
            {nickname: scope.session.amateur.getNickname()},
            openPayment.class
          );
        }
      }

      function onResize() {
        if (scope.session.client) {
          scope.$apply(() => {
            scope.session.client.resize();
          });
        }
      }

      scope.$on('$destroy', () => {
        if (as) as.show();
        stop();
      });

      function stop() {
        player.empty();
        if (scope.session) {
          scope.session.client.stopStream();
          scope.session.cam.stopLoading();
          scope.session.cam.stopRunning();
          LivecamSession.remove(scope.session.sid);
          setGlobalLivecamSession(null);
        }
      }

      function onStart() {
        scope.session.cam.startRunning();
        setGlobalLivecamSession(scope.session);
        const buttons = $compile(
          '<div onsw-hook class="popup frame center closeable columns pad10" hookname="livecamTipPopup" hook-data="session" ng-show="showButtons && showControls"></div>'
        )(scope);
        buttons.on('click', (ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          scope.$apply('showButtons = false');
        });
        player.children().first().append(buttons);
      }

      /**
       * @param {boolean} hidden
       */
      function onControls(hidden) {
        scope.$apply(() => (scope.showControls = !hidden));
      }

      function onTip() {
        scope.$apply('showButtons = true');
      }

      ctxSentMessage[CTX_OBSERVE](element[0], (message) => {
        if (
          message &&
          !message.fromLivecam &&
          message.amateur === scope.session.amateur &&
          Livecam.get(scope.session.amateur.id).showType != 'voyeur'
        ) {
          scope.session.client.addUserMessage(message.body);
        }
      });

      function start() {
        let options = {
          clientContainer: player,
          sizeElement: player,
          mode: isMobile ? 'mobile' : 'desktop',
          language: getLocale(),
          alternateDesign: true,
          noProgressBar: true,
          allowFullscreen: !(User.level < 10 && scope.session.type == 'free'), // TODO check this
        };

        var observer = new ResizeObserver(onResize);
        observer.observe(element[0]);
        scope.$on('$destroy', () => {
          observer.unobserve(element[0]);
        });
        scope.session.client.off('closed');
        scope.session.client.off('streamStarted');

        scope.session.client.on('streamStarted', onStart);
        scope.session.client.on('closed', onExit);
        scope.session.client.off('hideControls');
        scope.session.client.off('tip');
        scope.session.client.off('showChanged');
        scope.session.client.off('userMessage');

        scope.session.client.on('hideControls', onControls);
        scope.session.client.on('tip', onTip);
        scope.session.client.on('paymentRequired', function (code) {
          scope.$broadcast('LivecamShow.PaymentRequired', code);
        });
        scope.session.client.on('showChanged', function (show) {
          Livecam.get(scope.session.amateur.id).showType = show.showType;
          setGlobalLivecamSession(scope.session);
        });
        scope.session.client.on('userMessage', /** @param {string} body */ (body) => {
          publishUserMessage(new Message(scope.session.amateur, {
            fromLivecam: true,
            sender: {
              product_id: User.pool_id,
              id: `${User.id}`,
            },
            timestamp_ms: now(),
            payload: {},
            attachment: null,
            body,
          }));
        });

        scope.session.client.init(options);
        console.log({sessionId: scope.token, host: scope.session.host});
        scope.session.client.startStream({sessionId: scope.token, host: scope.session.host});
      }
    }
  },
]);
