/**
 * @module acng/games
 */
import angular from 'angular';
import {type as t4, name as n4, param as p4} from './widgets/game';
import onswGames, {gamesComponent} from './widgets/games';
import onswGameSuggestions, {suggestionsComponent} from './widgets/suggestions';
import './widgets/user-stats.js';
import './config/routes.js';
import {type as t11, param as p11} from './run/amateur';
import {extendSlideshow} from './decorator/slideshow';
import {hasFeature} from 'acng/core/service/env';
import './attributes/index.js';

const featureName = 'games';

if (hasFeature(featureName)) {
  extendSlideshow();
}

angular
  .module(featureName, ['core', 'userPool', 'amateurPool'])
  .config([
    '$translatePartialLoaderProvider',
    /**
     * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
     */
    $translatePartialLoaderProvider => {
      $translatePartialLoaderProvider.addPart(featureName);
    },
  ])
  [t4](n4, p4)
  .component(onswGames, gamesComponent)
  .component(onswGameSuggestions, suggestionsComponent)
  [t11](p11);
