/**
 * {@link !HTMLDetailsElement} with `data-auto-close` will be closed if you click anywhere outside.
 *
 * @example
 * ```html
 * <details>
 *   <summary>More</summary>
 *   Here is more.
 * </details>
 * ```
 *
 * @module <details>
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.83.0
 */

import {defineRegistryElement, defineRenderElement} from '@acng/frontend-stargazer';
import {ATTR_OPEN, TAGNAME_DETAILS} from '@acng/frontend-bounty/dom/type.js';
import {ObserveAttributes} from '@acng/frontend-bounty/dom/observe.js';
import {hasAttribute} from '@acng/frontend-bounty';

import {STYLE_OPEN, swapClass} from '../service/style.js';

const MODULE = 'layout/element/details';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryElement(TAGNAME_DETAILS, (name) => {
  /**
   * @param {HTMLDetailsElement} element
   */
  const update = (element) => swapClass(element, STYLE_OPEN, hasAttribute(element, ATTR_OPEN));
  const observe = ObserveAttributes([ATTR_OPEN], update, true);

  defineRenderElement(name, () => observe);
});
