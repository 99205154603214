import {listen, path, middleware} from '@acng/frontend-voyager';
import {noop} from '@acng/frontend-bounty';

import {rootRoute} from 'acng/core/config/routes';
import {amateurRoute} from 'acng/amateurPool/config/routes';
import {onlyUser} from 'acng/userPool/config/middleware';
import {listen as ebListen} from 'acng/core/context/event-bus.js';

import {gamesFeature} from './feature.js';
import {mainView} from 'acng/layout/service/scene.js';
import {userRoute} from 'acng/userPool/config/routes.js';
import {getLatestGame} from '../service/games.js';
import {ctxGame} from '../context/game.js';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';
import {inject} from 'acng/core/service/ng.js';

userRoute.create(path('/games'), mainView(gamesFeature, 'games'));

const gameTab = amateurRoute.create(
  onlyUser, //
  path('/game'),
  middleware(load),
  listen('enter', () => (unreg = ebListen('games.update', load))),
  listen('leave', () => unreg()),
  mainView(gamesFeature, 'game-tab')
);

let unreg = noop;

async function load() {
  DEBUG: console.debug('games/config/routes wait for amateur', {gameTab});
  await amateurRoute.wait;
  /** @typedef {import('acng/amateurPool/factory/Amateur').Amateur} Amateur */
  let amateur = /** @type {Amateur} */ (amateurRoute.nextParams.amateur);
  DEBUG: console.info('games/config/routes wait for session', {amateur});
  const session = await getLatestGame(amateur);
  if (session) {
    // TODO add amateur to getLatestGame
    session.amateur = amateur;
  }
  DEBUG: console.info('games/config/routes load', {session});
  ctxGame[CTX_PROVIDE](null, session ?? null);
  // set both so never trigger change?
  // TODO implement helper in router for this
  gameTab.nextParams.gameSession = session;
  gameTab.params.gameSession = session;
  rootRoute.globals.gameSession = session;
  inject('$rootScope').$digest();
}
