import {createTemplate, spliceArray, sizeOfArray} from '@acng/frontend-bounty';
import {createPerceptiveHTML, popup} from '@acng/frontend-discovery';
import {WatchGroup, createStream} from '@acng/frontend-relativity';
import {ctxGameSession} from '../context/session.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {getLatestGame, getSessions, sessionOfAmateur, sessions as gamesSessions} from '../service/games.js';

const watch = WatchGroup('onsw-games', ctxAmateur, ctxGameSession);

const logName = 'onswGames';
const VERBOSE_DEBUG = false;
export default logName;
export const gamesComponent: angular.IComponentOptions = {
  transclude: true,
  bindings: {
    onLoad: '&?',
  },
  controller: ['$element', 'Amateur', '$transclude', Controller],
};

type Session = import('../model/game').Session;
type Amateur = import('acng/amateurPool/factory/Amateur').Amateur;
type AmateurConstructor = import('acng/amateurPool/factory/Amateur').AmateurConstructor;
type SessionScope = angular.IScope & {
  amateur?: Amateur;
  session?: Session;
};
type OnLoad = (params: {sessions: Session[]}) => void;

const scopes = new WeakMap<object, angular.IScope>();

function Controller(
  this: angular.IController &
    angular.IOnChanges & {
      onLoad: OnLoad | null;
    },
  $element: JQLite,
  Amateur: AmateurConstructor,
  $transclude: angular.ITranscludeFunction
) {
  let sessions: Session[] = [];
  let omitSession: Session | undefined;
  const element = $element[0];

  const perceptive = createPerceptiveHTML(
    element,
    createStream([], async () => spliceArray(sessions, 0, sizeOfArray(sessions))),
    (session, loader) =>
      new Promise((resolve, reject) => {
        $transclude((clone, $scope) => {
          if (!clone || !$scope) {
            reject(Error(`${logName} template required`));
            return;
          }
          const scope = $scope as SessionScope;
          scopes.set(loader, scope);
          if (!session.amateurId) {
            reject(Error(`${logName} amateurId of session #${session.id} is not defined`));
          }
          Amateur.get(session.amateurId)
            .then((amateur) => {
              scope.amateur = amateur;
              session.amateur = amateur;
              scope.session = session;
              resolve(createTemplate("", ...clone));
            })
            .catch(reject);
        });
      }),
    (loader) => scopes.get(loader)?.$destroy()
  );

  DEBUG: if (VERBOSE_DEBUG) perceptive.enableDebug(logName);

  this.$onInit = () => watch(element, async (element, [amateur]) => {
    try {
      perceptive.disconnect();
      await getSessions();
      omitSession = amateur ? await getLatestGame(amateur) : undefined;
      DEBUG: console.debug('games/widgets/games changes', {omitSession});
      sessions = (
        amateur //
          ? [...gamesSessions.values()]
          : [...sessionOfAmateur.values()]
      )
        .filter(
          (s) =>
            (!amateur || s.amateurId == amateur.id) && //
            s != omitSession
        )
        .sort((a, b) => b.updatedTime - a.updatedTime);
      perceptive.connect();
      this.onLoad?.({sessions});
    } catch (reason) {
      console.error(`${logName} reason`);
      popup(element).error(`${reason}`);
    }
  });
}
