/**
 * A link with the `data-sedcard-href` attribute references a specified subpage on a `amateurRoute`,
 * which includes the "sedcard" and all subpages.
 *
 * @example
 * ```html
 * <a data-sedcard-href="/movies">Movies of this amateur</a>
 * ```
 *
 * @module data-sedcard-href
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.86.0
 * @see [Sedcard](../config/routes.js)
 */

import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {ctxAmateur} from '../context/amateur.js';
import {TAGNAME_ANCHOR, isHTMLElement} from '@acng/frontend-bounty/dom/type.js';
import {EVENT_CLICK, on} from '@acng/frontend-bounty';

const MODULE = 'amateurPool/attibute/sedcard-href';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-sedcard-href', (name) => {
  const watch = Watch(`[${name}]`, ctxAmateur);

  defineRenderAttribute(name, (element, value) => () => {
    watch(element, (element, amateur) => {
      const href = amateur ? `#/sedcard/${amateur.nickname}${value}` : '';

      if (isHTMLElement(element, TAGNAME_ANCHOR)) {
        element.href = href;
      } else {
        on(element, EVENT_CLICK, () => (location.href = href));
      }
      DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
        console.info(MODULE, 'setting href', element, {href});
      }
    });
  });
});
