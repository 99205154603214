angular.module('core')

  .directive('onswCategorySelect', ['$injector', 'orderByFilter', '$rootScope', 'Widget', '$location', ($injector, orderByFilter, $rootScope, Widget, $location) => ({
    scope: {
      feature: '@',
      onCategorySelect: '&'
    },
    template: `
<form class="box">
    <label>
        <div onsw-chosen placeholder="core.selectCategory" options="options" choose="categories"></div>
    </label>
    <label>
        <input type="text" ng-model="search" ng-model-options="{debounce: {default:1000, blur:0}}" placeholder="{{'core.searchTerm' | translate}}">
    </label>
</form>`,
    link: function (scope, element) {

      const widget = new Widget(scope, element, 'ons-form');

      scope.categories = [];
      scope.search = '';

      update();
      //$rootScope.$on('$translateChangeSuccess', update);
      //
      scope.$on('$locationChangeSuccess', update);

      function updateUrl() {
        $location.search('category', scope.categories?.length ? scope.categories.join(',') : null);
        $location.search('search', scope.search || null);
      }

      function update() {

        scope.search = $location.search().search;
        scope.categories = ($location.search().category || '').split(',').filter(v => v.length);
        scope.onCategorySelect({
          categories: scope.categories,
          search: scope.search
        });

        var api = $injector.get({
          picturePool: 'PictureSet',
          moviePool: 'Movie'
        }[scope.feature] || scope.feature);

        api.categories().then(function (res) {
          var options = [];
          for (var key in res) {
            options.push({
              key: key.toLowerCase(),
              val: res[key]
            });
          }
          scope.options = orderByFilter(options, 'val');
        });
      }

      var p;
      scope.$watch('search', (search, old) => {

        if (search === old) return;
        if (search && search.length <= 3) {
          p = widget.notify('core.notEnoughLetters', { min: 4 });
          return;
        }
        p && p();
        p = undefined;

        updateUrl();
      });

      scope.$watchCollection('categories', (categories, old) => {

        if (categories !== old && (!categories || !categories.length) && (!old || !old.length)) {
          if (!categories) {
            scope.categories = [];
          }
          return;
        }

        updateUrl();
      });

    }
  })]);
