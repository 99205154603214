/**
 * @module amateurPool/widgets/amateur-list-small
 *
 * What it does:
 * - Show an icon according to the amateur.
 * What it does NOT:
 * - Show loading animation.
 */
import {getAttribute, toggleClass} from '@acng/frontend-bounty';
import {createPerceptiveHTML} from '@acng/frontend-discovery';
import {createStream} from '@acng/frontend-relativity';
import {CTX_OBSERVE, CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {inject} from 'acng/core/service/ng';
import {ctxOnline} from '../service/online.js';
import {getAmateurVisits} from 'acng/amateurPool/service/profile-visit.js';
import {Rendering, defineRegistryElement, defineScope, stargazer} from '@acng/frontend-stargazer';
import {
  connectedCallback,
  defineElement,
  disconnectedCallback,
  HTMLElement,
} from '@acng/frontend-bounty/dom/custom.js';
import {TAGNAME_TEMPLATE} from '@acng/frontend-bounty/dom/type.js';
import {ctxAmateur} from '../context/amateur.js';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {AmateurTile} from '../tiles/AmateurTile.js';

const MODULE = 'amateurPool/widget/list';
const VERBOSE = false;

defineRegistryElement('onsw-amateur-list', (name) => {
  const STYLE_MESSENGER_ONLINE = 'messenger-online';
  const STYLE_LIVECAM_ONLINE = 'livecam-online';
  const STYLE_LIVECAM_TOYCONTROL = 'livecam-toycontrol';

  const Amateur = inject('Amateur');

  defineScope(name, ['nickname']);

  ctxOnline.subscribeTagName(`${name} > article:not(:empty)`);

  /**
   * @type {WeakMap<object, import('angular').IScope>}
   */
  const scopes = new WeakMap();

  defineElement(
    name,
    class extends HTMLElement {
      #renderItem = (() => {
        const fromAttribute = getAttribute(this, 'item-template');

        if (fromAttribute) {
          return stargazer.render(...fromAttribute.split('.'));
        }

        const childTemplate = query(this, TAGNAME_TEMPLATE);

        if (!childTemplate) {
          throw Error();
        }

        return new Rendering(childTemplate);
      })();

      #stream = createStream([], async (offset) => {
        if (offset) {
          return [];
        }

        if (this.dataset.name == 'visits') {
          return getAmateurVisits();
        }

        return inject('AmateurFilter')
          .get(this.dataset.name ?? '')
          .list(20000);
      });

      #warpdrive = createPerceptiveHTML(
        this,
        this.#stream,
        async (amateurId, loader) => {
          const rendering = await this.#renderItem;
          const amateur = await Amateur.get(amateurId);
          const image = await amateur.getImage('800');

          if (!loader.target) {
            return;
          }

          if (rendering.name == 'tileAmateur') {
            const tile = new AmateurTile(amateurId);
            scopes.set(loader, tile.scope);
            return tile.render(rendering, loader);
          }

          const element = loader.target;
          ctxAmateur[CTX_PROVIDE](element, amateur);
          const fragment = rendering.toElement(element, {
            nickname: amateur.getNickname(),
            image: image.src,
            gotoSedcard: () => amateur.goto(),
          });

          // ctxLivecams[CTX_OBSERVE](element), () => {
          toggleClass(element, STYLE_LIVECAM_ONLINE, amateur.livecamIsOnline());
          toggleClass(element, STYLE_LIVECAM_TOYCONTROL, amateur.hasToycontrol());
          // });

          ctxOnline[CTX_OBSERVE](loader.target, async (isOnline) => {
            toggleClass(element, STYLE_MESSENGER_ONLINE, isOnline(amateur.id));
          });
        },
        (loader) => scopes.get(loader)?.$destroy()
      );

      [connectedCallback]() {
        const host = this;
        const stream = this.#stream;
        const perceptive = this.#warpdrive;

        DEBUG: if (VERBOSE) this.#warpdrive.enableDebug(`${host.localName}:${host.dataset.name ?? 'ERROR'}`);
        stream.streamReset([]);
        perceptive.connect();
      }

      [disconnectedCallback]() {
        this.#warpdrive.disconnect();
      }
    }
  );
});
