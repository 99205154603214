import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {removeNode, sizeOfArray} from '@acng/frontend-bounty';
import {ENUM, typeguard} from '@acng/frontend-bounty/typeguard.js';

import {animate} from 'acng/layout/service/animate-number.js';
import {ctxFriendship, friendships} from '../context/friendships.js';
import {POSSIBLE_STATES, STATE_PENDING} from '../service/friendship.js';
import {friendFeature} from '../config/feature.js';

const MODULE = 'friend/attribute/data-friend-count';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-friend-count', (name) => {
  DEBUG: if (VERBOSE) console.debug(MODULE, 'setup', name);

  defineCompileAttribute(name, (element) => {
    DEBUG: if (VERBOSE) element.setAttribute('debug', '');

    if (friendFeature.isHidden) {
      DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, 'hidden feature', {name});

      removeNode(element);
    }
  });

  const watch = Watch(`[${name}]`, ctxFriendship);

  defineRenderAttribute(name, (element, type) => {
    type ||= STATE_PENDING;
    ASSERT: typeguard(MODULE, type, ENUM(POSSIBLE_STATES));

    watch(element, (element, friendship) => {
      const count = sizeOfArray(friendships[type]);
      animate(element, count, friendship?.status == STATE_PENDING ? 0 : count);
    });
  });
});
