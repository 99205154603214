/**
 * User actions are debounced with a 100ms delay.
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.86.0
 */

import {EVENT_CLICK, EVENT_SUBMIT, off, on} from '@acng/frontend-bounty';
import {DiscardingQueue} from '@acng/frontend-bounty/timing/discarding-queue.js';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';

const MODULE = 'layout/service/worker';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @param {Element} element
 * @param {(element: Element, evt: MouseEvent) => unknown} action
 */
export const onClick = (element, action) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
    console.debug(MODULE, 'add click listener', element, {action});
  }
  ASSERT: typeguard(MODULE, element, IS(HTMLElement));

  /**
   * @param {MouseEvent} evt
   */
  const listener = (evt) => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'click', element, {action});
    queue(() => action(element, evt));
  };

  on(element, EVENT_CLICK, listener);

  return () => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
      console.debug(MODULE, 'remove click listener', element, {action});
    }
    off(element, EVENT_CLICK, listener);
  };
};

/**
 * @param {Element} form
 * @param {(element: Element, data: Record<string, FormDataEntryValue>, evt: SubmitEvent) => unknown} action
 */
export const onSubmit = (form, action) => {
  DEBUG: if (VERBOSE || form.hasAttribute('debug')) {
    console.debug(MODULE, 'add submit listener', form, {action});
  }
  ASSERT: typeguard(MODULE, form, IS(HTMLFormElement));

  /**
   * @param {SubmitEvent} evt
   */
  const listener = (evt) => {
    DEBUG: if (VERBOSE || form.hasAttribute('debug')) console.info(MODULE, 'click', form, {action});
    queue(() => action(form, Object.fromEntries(new FormData(form)), evt));
  };

  on(form, EVENT_SUBMIT, listener);

  return () => {
    DEBUG: if (VERBOSE || form.hasAttribute('debug')) {
      console.debug(MODULE, 'remove submit listener', form, {action});
    }
    off(form, EVENT_SUBMIT, listener);
  };
};

const queue = DiscardingQueue(100, true);

DEBUG: if (VERBOSE) {
  queue.enableDebug(MODULE);
}
