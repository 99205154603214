import {path} from '@acng/frontend-voyager';

import {userRoute} from 'acng/userPool/config/routes';

import {achievementFeature} from '../config/feature';
import {mainView} from 'acng/layout/service/scene.js';

if (achievementFeature.isEnabled) {
  userRoute.create(
    path('/achievements'),
    mainView(achievementFeature, 'achievements')
  );
}
