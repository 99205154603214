/**
 * Provide the most recent dialog message.
 *
 * @see [Messenger Contact](../tiles/ContactTile.js)
 *
 * All recent messages are stored. The store is cleared when the
 * current user changes or his event bus disconnects.
 *
 * This module is also crucial for the evaluation of icebreaker messages.
 * Because it must provide the information for statistics whether an icebreaker is answered.
 *
 * @module
 */

import {createMap, Store, set, clear} from '@acng/frontend-bounty/collection.js';
import {resolved} from '@acng/frontend-bounty/promise.js';
import {GlobalWatchGroup} from '@acng/frontend-relativity';

import {Message} from '../model/message.js';
import {request} from 'acng/core/context/event-bus.js';
import {typeguard, RECENT_MESSAGE_DATA} from './typeguard.js';
import {ctxNewMessage} from '../context/message.js';
import {ctxAuthUser} from 'acng/userPool/context/auth-user.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';

const MODULE = 'messenger/service/recent-message';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.info(`Verbose import ${MODULE}`);

/**
 * In case of doubt, the decision is made to the disadvantage of the icebreaker.
 *
 * @param {RecentMessagePartner} amateur
 * @returns {Promise<boolean>}
 */
export const recentMessageIsIcebreaker = async (amateur) =>
  ((await getRecentMessage(amateur))?.payload.ibid ?? 0) > 0;

/**
 * @type {Map<RecentMessagePartner, Promise<?Message>>}
 */
const messages = createMap();
const M = 'message';

/**
 * In case of an error, it is assumed that there is no previous message.
 *
 * @returns The most recent dialog message.
 */
export const getRecentMessage = Store(messages, async (amateur) => {
  DEBUG: if (VERBOSE) console.info(`${MODULE} <--?`, {amateur});

  const data = await request({type: 'recent-message', partner: {product_id, id: amateur.id}}, 5).catch(
    (reason) => {
      DEBUG: console.error(MODULE, reason);

      return {[M]: null};
    }
  );

  DEBUG: if (VERBOSE) console.info(`${MODULE} !-->`, {amateur, data});
  ASSERT: typeguard(MODULE, data[M], RECENT_MESSAGE_DATA());

  return data[M] ? new Message(amateur, data[M]) : null;
});

/**
 * @typedef {import('acng/amateurPool/factory/Amateur').Amateur} RecentMessagePartner
 */

/**
 * @type {number | undefined}
 */
let product_id;

// Perform cache updates and purge.
GlobalWatchGroup(
  ctxAuthUser,
  ctxEventBusUser,
  ctxNewMessage
)(([user, connected, message], [prevUser]) => {
  DEBUG: if (VERBOSE) console.debug(`${MODULE} watch`, {user, connected, message});

  product_id = user?.partner_pool;

  if (!connected || !user || user.id != prevUser?.id) {
    DEBUG: if (VERBOSE) console.info(`${MODULE} clear stored messages`);

    clear(messages);
    return;
  }

  if (message) {
    DEBUG: if (VERBOSE) console.info(`${MODULE} update`, {amateur: message.amateur, message});

    set(messages, message.amateur, resolved(message));
  }
});
