/**
 * @module livecam/config/routes
 */
import {userRoute} from 'acng/userPool/config/routes';
import {middleware, path, index, alias, validate, incomplete, createMany} from '@acng/frontend-voyager';
import {mainView, stickyView} from 'acng/layout/service/scene.js';
import {livecamFeature} from './feature.js';

userRoute.createMany(
  [
    path('/livecamShow/{livecamShow}'), //
    index,
    alias('livecamShow'),
  ],
  [
    incomplete,
    path('/livecams'),
    stickyView(livecamFeature, 'list-header'),
    createMany(
      [
        path('/{livecams}'), //
        validate(({livecams}) => /^(all|online|new|toycontrol)$/.test(/** @type {string} */ (livecams))),
        mainView(livecamFeature, 'list'),
        alias('livecams'),
      ],
      [
        index,
        middleware(() => {
          DEBUG: console.warn('livecam/config/routes deprecated route - redirect');
          location.hash = '/livecams/online';
        }),
      ]

    ),
  ]
);
