/**
 * Continuously provide the users vip points value to the ACNG frontend.
 *
 * @see [VIP-Points Button](../widget/points.js)
 * @see [Latest Achievements List](../widget/latest.js)
 *
 * @module
 */

import {createGlobalContext, GlobalWatch} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {listen} from 'acng/core/context/event-bus.js';
import {ctxAuthUser} from 'acng/userPool/context/auth-user.js';
import {typeguard, EVENTBUS_USER} from 'acng/userPool/service/typeguard.js';

const MODULE = 'achievement/service/sock';
const VERBOSE = false;

/**
 * Provides the current users vip points value and updates.
 */
export const ctxVipPoints = createGlobalContext(NaN);

GlobalWatch(ctxAuthUser)((user) => ctxVipPoints[CTX_PROVIDE](null, user?.points ?? NaN));

listen('user', (data) => {
  ASSERT: typeguard(MODULE, data, EVENTBUS_USER());
  DEBUG: if (VERBOSE) console.info(`${MODULE}`, data);

  ctxVipPoints[CTX_PROVIDE](null, data.user.points);
});


/*
let fakePoints = 0;
(function fake() {
  fakePoints += Math.round(Math.random() * 50) + 1;
  ctxVipPoints[CTX_PROVIDE](null, fakePoints);
  setTimeout(() => {
    fake();
  }, Math.random() * 5000 + 500);
})();
*/
