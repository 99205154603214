const ngComponentName = 'onswMessengerMessageBox';
export default ngComponentName;
/**
 * @type {angular.IComponentOptions}
 */
export const messagesComponent = {
  bindings: {
    voicemessageInfo: '<',
  },
  templateUrl: '/template/messenger.messages', // TODO template override exists!
  controller: ['$scope', '$element', '$timeout', '$compile', '$attrs', controller],
};

import angular from 'angular';
import {WatchGroup} from '@acng/frontend-relativity';
import {CTX_VALUE} from '@acng/frontend-relativity/minify';

import {Message} from '../model/message';
import {listen, publish} from 'acng/core/context/event-bus.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';
import {typeguard, EVENTBUS_MESSAGES_DATA, EVENTBUS_MESSAGE_DATA} from '../service/typeguard.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {getDialog} from '../service/dialogs.js';
import {removeClass, toggleClass} from '@acng/frontend-bounty';

const watch = WatchGroup('onsw-messenger-message-box', ctxAmateur, ctxEventBusUser);

/**
 * @this {angular.IController & {voiceMessageInfo: boolean}}
 * @param {Scope} scope
 * @param {JQLite} element
 * @param {angular.ITimeoutService} $timeout
 * @param {angular.ICompileService} $compile
 * @param {angular.IAttributes} $attrs
 */
function controller(scope, element, $timeout, $compile, $attrs) {
  const host = element[0];
  const scrollBox = angular.isDefined($attrs.disableScroll)
    ? null
    : element.closest('.ons-scroll').children('.box');
  const dialogBox = element.parents('.messenger-dialog');

  function blubb() {
    if (scrollBox?.parent().hasClass('scroll-min')) {
      dialogBox.removeClass('short');
      scrollBox?.off('scroll', blubb);
    }
  }

  scope.$watch('messages.length', () =>
    $timeout(() => scrollBox?.animate({scrollTop: scrollBox[0].scrollHeight}), 100)
  );

  const offMessage = listen('message', (data, reply) => {
    ASSERT: typeguard('', data, EVENTBUS_MESSAGE_DATA());

    if (!data.message) {
      return;
    }

    const amateur = ctxAmateur[CTX_VALUE](host);

    if (!scope.messages || data.partner.id !== amateur?.id) {
      return;
    }

    const msg = new Message(amateur, data.message);
    if (msg.sender.id === amateur.id) {
      msg.translate().then(() => {
        scope.messages?.push(msg);
        reply(markDialogAsRead(amateur.id));
      });
    } else {
      msg.ready = true;
      scope.messages.push(msg);
    }
  });

  watch(element[0], (element, [amateur, user]) => {
    toggleClass(element, 'offline', !user);
    delete scope.messages;
    removeClass(element, 'ready'); // TODO ready (animation)
    this.amateur = amateur;

    if (!user || !amateur) {
      return;
    }

    scrollBox?.on('scroll', blubb);
    dialogBox.addClass('short');
    const dialog = getDialog(amateur.id, true);
    scope.voicemessageInfo = this.voicemessageInfo;
    publish({type: 'messages', partner: {product_id: user.partner_pool, id: amateur.id}});
  });

  const offMessages = listen('messages', (data, reply) => {
    ASSERT: typeguard('', data, EVENTBUS_MESSAGES_DATA());

    const amateur = ctxAmateur[CTX_VALUE](host);

    if (data.partner.id !== amateur?.id) {
      return;
    }
    scope.messages = data.messages.map((messageData) => {
      const message = new Message(amateur, messageData);
      message.translate();
      return message;
    });

    reply(markDialogAsRead(amateur.id));
  });

  this.$onDestroy = () => {
    scrollBox?.off('scroll', blubb);
    offMessages();
    offMessage();
  };

  scope.displayAttachment = ($event, url) => {
    if (url.match(/\.jpe?g$/i)) {
      $compile('<image-overlay src="' + url + '"></image-overlay>')(scope).appendTo(angular.element('body'));
      $event.preventDefault();
    }
  };
}

/**
 * @param {string} partner
 */
const markDialogAsRead = (partner) => ({
  type: 'dialog.read',
  partner: {
    product_id: 2,
    id: partner,
  },
});

/**
 * @typedef {angular.IScope & {
 *   messages?: Message[];
 *   displayAttachment(evt: Event, url: string): void;
 *   voicemessageInfo?: boolean;
 * }} Scope
 */
