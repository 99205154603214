import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {pushToArray, removeNode} from '@acng/frontend-bounty';

import {animate} from 'acng/layout/service/animate-number.js';
import {ctxGameSession} from '../context/session.js';
import {getSessions, sessionOfAmateur} from '../service/games.js';
import {inject} from 'acng/core/service/ng.js';

const MODULE = 'games/attributes/data-game-count';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-game-count', (name) => {
  DEBUG: if (VERBOSE) console.debug(MODULE, 'setup', {name});
  defineCompileAttribute(name, (element, _value, promises) => {
    DEBUG: if (VERBOSE) element.setAttribute('debug', '');
    if (!inject('user').hasGames()) {
      DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, 'hidden feature', {name});
      removeNode(element);
    } else {
      pushToArray(promises, getSessions());
    }
  });

  const watch = Watch(`[${name}]`, ctxGameSession);

  defineRenderAttribute(name, (element) => {
    let previous = 0;

    watch(element, (element) => {
      let count = 0;
      for (const session of sessionOfAmateur.values()) {
        if (session.getState().isActive) {
          count++;
        }
      }
      DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, {count, previous});
      animate(element, count, previous);
      previous = count;
    });

    /*
    let fakeVal = 0;
    (function fake() {
      setTimeout(() => {
        animate(element, ++fakeVal, fakeVal - 1);
        fake();
      }, Math.random() * 10000 + 500);
    })();
    */
  });
});
