import {setText} from '@acng/frontend-bounty';
import {
  HTMLElement,
  connectedCallback,
  defineElement,
  disconnectedCallback,
} from '@acng/frontend-bounty/dom/custom.js';
import {typeguard} from '@acng/frontend-bounty/typeguard.js';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {listen} from 'acng/core/context/event-bus.js';
import {ctxAuthUser} from 'acng/userPool/context/auth-user.js';
import {EVENTBUS_USER} from 'acng/userPool/service/typeguard.js';

const MODULE = 'payment/element/coin-amount';

defineElement(
  'payment-coin-amount',
  class extends HTMLElement {
    /**
     * @type {(() => void) | undefined}
     */
    #unreg;

    [connectedCallback]() {
      ctxAuthUser[CTX_OBSERVE](this, (authUser) => {
        setText(this, `${authUser?.coins ?? ''}`);
      });
      this.#unreg = listen('user', (data) => {
        ASSERT: typeguard(MODULE, data, EVENTBUS_USER());
        setText(this, `${data.user.coins}`);
      });
    }

    [disconnectedCallback]() {
      this.#unreg?.();
    }
  }
);
