import {CTX_ALERT, CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME, CTX_UNOBSERVE} from "@acng/frontend-relativity/minify";
import {ctxStockUpdate} from "acng/userPool/context/stock-update.js";

ctxStockUpdate[CTX_SUBSCRIBE_TAGNAME]('.picture-box');

angular.module('picturePool')

  .factory('PictureThumbnail', ['PictureSetTile', 'Picture', 'Widget', 'StockItem', (PictureSetTile, Picture, Widget, StockItem) => {
    class PictureThumbnail extends PictureSetTile
    {
      dontSetBg = true;
      async load(scope, picture_id) {
        const widget = new Widget(scope, angular.element(this.el));
        const pic = await Picture.get(picture_id);
        const stock = (await StockItem.check('Picture', picture_id))?.is_valid;
        this.apply({
          picture: pic,
          image: stock ? pic.getImageUrl() : pic.getBlurredImageUrl(),
          click: () => widget.ifNotBusy(pic.slideshowFn()),
          mediaId: pic.id,
          mediaType: 'Picture'
        });
        await super.load(scope, pic.set_id);
        this.box.style.overflow = 'hidden';
        this.box.style.height =
      this.image.style.height =
      this.image.style.width = '100%';
        this.image.style['object-fit'] = 'cover';

        // only blur in dev / staging env
        if (pic.getImageUrl() == pic.getBlurredImageUrl() && !stock) {
          this.image.style.filter = 'blur(15px)';

        }
        if (this.dontSetBg) {
          this.box.prepend(this.image);
        }

        if (!stock) {
          const onPurchase = (stockItem) => {
            if (stockItem?.type == 'Picture' && stockItem.article_id == picture_id) {
              this.reset();
              this.load(scope, picture_id);
            } else {
              ctxStockUpdate[CTX_ALERT](this.box, onPurchase);
            }
          };
          ctxStockUpdate[CTX_ALERT](this.box, onPurchase);
        }

        return pic;
      }
      hookname() {
        return 'picture';
      }
    }
    return PictureThumbnail;
  }]);
