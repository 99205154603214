/**
 * Show the current number of all unread messenger messages and provide CSS classes to design
 * the display in the browser according to this number.
 *
 * Elements matching `[data-inbox-count]` will be removed if the "messenger" feature is not active.
 * Otherwise everything else happens is determined by *animate-number*.
 *
 * @example
 * ```html
 * <a data-inbox-class> Dialogs </a>
 * ```
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.84.0
 * @see [animate-number](../../layout/service/animate-number.js)
 */

import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
import {removeNode} from '@acng/frontend-bounty';
import {Watch} from '@acng/frontend-relativity';

import {animate} from 'acng/layout/service/animate-number.js';
import {ctxUnreadDialogs} from '../context/unread-dialogs.js';
import {messengerFeature} from '../config/feature.js';

const MODULE = 'messenger/attribute/data-inbox-class';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-inbox-count', (name) => {
  DEBUG: if (VERBOSE) console.debug(MODULE, 'setup', name);

  defineCompileAttribute(name, (element) => {
    DEBUG: if (VERBOSE) element.setAttribute('debug', '');

    if (messengerFeature.isHidden) {
      DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, 'hidden feature', {name});

      removeNode(element);
    }
  });

  const watch = Watch(`[${name}]`, ctxUnreadDialogs);

  defineRenderAttribute(name, (element) => watch(element, animate));
});
