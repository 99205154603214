import {document, hasClass, isInstanceOf, isNull, setAttribute} from '@acng/frontend-bounty';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';

import {onClickElement} from '@acng/frontend-bounty/dom/event.js';
import {skipPath} from './auto-close.js';
import {STYLE_MENU_MAIN, STYLE_OPEN, swapClass} from '../service/style.js';
import {IS, REGEX, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {ATTR_OPEN, HTMLDetailsElement} from '@acng/frontend-bounty/dom/type.js';
import {STYLE_ACTIVE} from '../config/style.js';

const MODULE = 'layout/attribute/toggle-menu';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-toggle-menu', (name) => {
  DEBUG: if (VERBOSE) console.info(MODULE, 'setup', name);

  const OPEN = 'open';
  const CLOSE = 'close';

  defineRenderAttribute(name, (element, value) => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.debug(MODULE, {element, value});
    ASSERT: typeguard(MODULE, value, REGEX(/^(open|close)?$/));

    const force = value === OPEN ? true : value === CLOSE ? false : null;

    if (isNull(force)) {
      swapClass(element, STYLE_ACTIVE, false);
    }

    onClickElement(element, () => {
      const target = query(document, `.${STYLE_MENU_MAIN}`);
      ASSERT: typeguard(MODULE, target, IS(HTMLElement));
      const isOpen = force ?? !hasClass(target, STYLE_OPEN);

      if (isNull(force)) {
        swapClass(element, STYLE_ACTIVE, isOpen);
      }

      DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.debug(MODULE, {element, target, isOpen});

      skipPath(target);
      if (isInstanceOf(target, HTMLDetailsElement)) {
        setAttribute(target, ATTR_OPEN, '');
      } else {
        swapClass(target, STYLE_OPEN, isOpen);
      }
    });
  });
});
