/**
 * Hide content when the user is authenticated.
 *
 * Remove {@link !Element} matching `[data-only-user]` if the `authUser` is set.
 *
 * @example
 * ```html
 * <div data-only-guest>You are not logged in yet!</div>
 * ```
 *
 * @module data-only-guest
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.84.0
 * @see [authUser](../context/auth-user.js)
 */

import {removeNode} from '@acng/frontend-bounty';
import {defineCompileAttribute} from '@acng/frontend-stargazer';

import {authUser} from '../context/auth-user.js';

const MODULE = 'userPool/attribute/only-guest';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineCompileAttribute('data-only-guest', (element) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.debug(MODULE, {element});

  if (authUser) {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'remove', {element});

    removeNode(element);
  }
});
