/**
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {middleware, rootRoute} from '@acng/frontend-voyager';
import {HTMLTemplateElement, append, isInstanceOf} from '@acng/frontend-bounty';
import {off, on} from '@acng/frontend-bounty/dom/event.js';
import {createFragment, createWithIdAndNodes} from '@acng/frontend-bounty/dom/template.js';
import {FeatureUtility} from '@acng/frontend-stargazer';
import {MapStore} from '@acng/frontend-bounty/collection.js';
import {join} from '@acng/frontend-bounty/std/array.js';

const verbose = true;

/**
 */
export const getScene = MapStore(createFragment);

/**
 * @param {string} name
 */
const ViewMiddleware = (name) => {
  const scene = getScene(name);

  /**
   * @param {FeatureUtility | HTMLTemplateElement} feature
   * @param {string[]} path
   */
  return (feature, ...path) => {
    if (isInstanceOf(feature, HTMLTemplateElement)) {
      feature = new FeatureUtility(feature);
    }

    const fullPath = [feature.name, ...path];
    const id = join(fullPath, '-');
    const template = createWithIdAndNodes(id, `#{${join(fullPath, '.')}}`);

    /**
     * @param {import("@acng/frontend-voyager").Route} route
     */
    return (route) =>
      middleware(() => {
        const enter = () => {
          DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" enter`, {feature, path});
          append(scene, template);
          on(
            route,
            'leave',
            () => {
              DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" leave`, {feature, path});
              template.remove();
            },
            {once: true}
          );
        };
        on(route, 'enter', enter, {once: true});
        on(
          rootRoute,
          'settled',
          () => {
            DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" settled`);
            off(route, 'enter', enter);
          },
          {once: true}
        );
      })(route);
  };
};

export const mainView = ViewMiddleware('main');
export const stickyView = ViewMiddleware('sticky');
export const footerView = ViewMiddleware('footer');
