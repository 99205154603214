/**
 * @module acng/core/widgets/tile
 */
import {getLocale} from 'acng/locale/model/locale.js';

import {hasFeature} from '../service/env';
import {RootScope} from 'acng/zz-app';
import {IAttributes, IDirective, ITranscludeFunction} from 'angular';
import {Scope} from './hook';

onswTileDirective.$inject = ['$animate', '$rootScope', '$injector'];
export function onswTileDirective(
  $rootScope: RootScope
) {
  $rootScope.hasFeature = hasFeature;
  $rootScope.locale = getLocale;

  return {
    restrict: 'A',
    scope: false,
    transclude: 'element',
    controller,
  } as IDirective;
}

controller.$inject = ['$scope', '$element', '$attrs', '$transclude', '$animate', '$injector'];
function controller(
  scope: Scope, //
  element: JQLite,
  attrs: IAttributes,
  $transclude: ITranscludeFunction,
  $animate: angular.animate.IAnimateService,
  $injector: angular.auto.IInjectorService,
) {
  if (!$injector.has(attrs.onswTile)) {
    return;
  }
  const tileClass: any = $injector.get(attrs.onswTile);
  let tile: any;

  /** */
  function hide() {
    if (tile) {
      const oldTile = tile;
      tile = null;
      $animate.leave(oldTile.el).then(() => oldTile.scope.$destroy());
    }
  }

  attrs.$observe('arg', (arg?: string) => {
    //$log.debug('ONSWTILE got arg', arg);
    hide();
    //const config = JSON.parse(arg);
    const config = scope.$eval(arg ?? '');

    $transclude((clone, $scope) => {
      const newScope = scope.$new(true) as Scope;
      tile = new tileClass(config, newScope, (clone as JQLite)[0]);
      //console.log('ONSWTILE', tile);
      element.addClass(tile.hookname().toDash() + '-hook');
      //tile.setMeta(0);
      newScope.locale = getLocale;
      newScope.hasFeature = hasFeature;
      show();
      /** */
      function show() {
        tile
          .get(attrs.arg)
          // @ts-expect-error
          .then(() => $animate.enter(tile.el, false, element))
          .catch((err: unknown) => console.warn('core/widgets/tile', tile, err));
      }

      newScope.$on('tile.update', (_event, tile) => {
        if (tile.disabled) hide();
        else show();
      });
    });
  });
}
