/**
 * Enable styles according to the current browser location.
 *
 * A {@link !HTMLAnchorElement} will become `layout-active` if it lead to the current location.
 * It will become `layout-inactive` otherwhise.
 *
 * A {@link !HTMLDetailsElement} will become `layout-active` if the subtree contains an `a.layout-active`.
 * It will become `layout-inactive` otherwhise.
 *
 * @example
 * ```html
 * <nav>
 *   <a href="/">Home</a>
 *   <a href="/settings.html">Settings</a>
 *   <details>
 *     <summary>Special</summary>
 *     <a href="/special/a.html">A</a>
 *     <a href="/special/b.html">B</a>
 *     <a href="/special/c.html">C</a>
 *   </details>
 * </nav>
 * ```
 *
 * @module <a>
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.83.0
 */
import {defineRegistryElement, defineRenderElement} from '@acng/frontend-stargazer';
import {body, getAttribute, isInstanceOf, parentElement} from '@acng/frontend-bounty';
import {ObserveAttributes} from '@acng/frontend-bounty/dom/observe.js';
import {
  ATTR_HREF,
  TAGNAME_ANCHOR,
  TAGNAME_DETAILS,
  HTMLDetailsElement,
} from '@acng/frontend-bounty/dom/type.js';
import {queryAll} from '@acng/frontend-bounty/dom/query.js';

import {inject} from 'acng/core/service/ng.js';
import {swapClass, STYLE_ACTIVE} from '../service/style.js';

const MODULE = 'layout/element/a';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryElement(TAGNAME_ANCHOR, (name) => {
  DEBUG: if (VERBOSE) console.debug(MODULE, 'setup', {name});

  /**
   * Determine if the given `element` points to the current location.
   *
   * The check is done by testing if the elements "href" attribute value
   * is found in {@link !Location["hash"]}
   *
   * Since {@link !HTMLAnchorElement["href"]} is tainted by the browser,
   * use the raw corresponding attribute value.
   *
   * @param {HTMLAnchorElement} element
   * @returns {void}
   */
  const toggleAnchorElement = (element) => {
    const href = getAttribute(element, ATTR_HREF);
    const locationHref = location.hash;
    const match = locationHref === href;

    DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
      console[match ? 'info' : 'debug'](MODULE, {element, match, href, locationHref});
    }

    swapClass(element, STYLE_ACTIVE, match);

    if (match) {
      /**
       * @type {?Element}
       */
      let details = element;
      while ((details = parentElement(details))) {
        if (isInstanceOf(details, HTMLDetailsElement)) {
          DEBUG: if (VERBOSE || details.hasAttribute('debug')) console.info(MODULE, 'active', {details});

          swapClass(element, STYLE_ACTIVE);
        }
      }
    }
  };

  const observeHref = ObserveAttributes([ATTR_HREF], toggleAnchorElement, true);

  defineRenderElement(name, () => observeHref);

  inject('$rootScope').$on('$locationChangeSuccess', () => {
    DEBUG: if (VERBOSE) console.debug(MODULE, 'location change');

    for (const details of queryAll(body, TAGNAME_DETAILS)) {
      DEBUG: if (VERBOSE || details.hasAttribute('debug')) console.debug(MODULE, 'reset', {details});

      swapClass(details, STYLE_ACTIVE, false);
    }

    for (const element of queryAll(body, TAGNAME_ANCHOR)) {
      toggleAnchorElement(element);
    }
  });
});
