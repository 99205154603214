import {removeClass, body, addClass} from '@acng/frontend-bounty';
import {WatchGroup} from '@acng/frontend-relativity';
import {ctxGlobalDialog} from 'acng/messenger/context/global-dialog';
import {ctxSentMessage} from 'acng/messenger/context/message.js';

export default 'onswRegardCloseShop';
export const closeComponent: angular.IComponentOptions = {
  template: '<span class="box" ng-click="$ctrl.close()"></span>',
  controller: ['$element', controller],
};

const watch = WatchGroup('onsw-regard-close-shop', ctxGlobalDialog, ctxSentMessage);

function controller(
  this: angular.IController & {close(): void},
  $element: JQLite,
) {
  const element = $element[0];
  addClass(element, 'ons-item');
  this.close = () => removeClass(body, 'regard-shop-open');
  watch($element[0], this.close);
}
