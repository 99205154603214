import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {inject} from 'acng/core/service/ng';
import {removeNode, toggleClass} from '@acng/frontend-bounty';
import {WatchGroup} from '@acng/frontend-relativity';
import {ctxGameSession} from '../context/session.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';

defineRegistryAttribute('data-sedcard-game', (name) => {
  const watch = WatchGroup(`[${name}]`, ctxAmateur, ctxGameSession);

  defineRenderAttribute(
    name,
    () => (element) =>
      watch(element, (element, [amateur]) => {
        if (!amateur || !inject('user').hasGames() || !amateur.hasGames()) {
          removeNode(element);
          return;
        }

        const gameState = amateur?.getGame()?.getState();
        if (gameState) {
          for (const key in gameState) {
            // @ts-expect-error
            toggleClass(element, key, gameState[key]);
          }
        }
      })
  );
});
