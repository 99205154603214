import {setAttribute} from '@acng/frontend-bounty';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {defineCompileAttribute} from '@acng/frontend-stargazer';

const MODULE = 'layout/attribute/data-title';

defineCompileAttribute('data-title', (element) => {
  ASSERT: typeguard(MODULE, element, IS(HTMLElement));
  setTitleToInnerText(element);
});

/**
 * @param {HTMLElement} element
 */
export const setTitleToInnerText = (element) => {
  setAttribute(element, 'title', element.innerText ?? '');
};

