/**
 * An `Element` with the `:without-feature` attribute will be removed if the
 * named feature is enabled on this portal.
 *
 * @example
 * ```html
 * <div :without-feature="livecam">
 *   <!-- The div is removed if the livecam feature enabled on the portal. -->
 * </div>
 * ```
 *
 * @module :without-feature
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.83.0
 * @see [hasFeature](../service/env.js)
 */

import {defineCompileAttribute} from '@acng/frontend-stargazer';
import {removeNode} from '@acng/frontend-bounty';

import {hasFeature} from '../service/env.js';

const MODULE = 'core/attribute/without-feature';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineCompileAttribute(':without-feature', (element, featureName) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.debug(MODULE, {element});

  if (hasFeature(featureName)) {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'remove', {element});

    removeNode(element);
  }
});
