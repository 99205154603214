import angular from 'angular';

import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';

import {runSedcardNav} from './config/routes';
import {runLaforge} from './config/laforge';
import onswAmateurContext, {contextComponent} from './widgets/context';
import './widgets/otm-shuffle';
import './widgets/list-small';
import './widgets/sedcard-image.js';
import Otm, {OtmFactory} from './factory/Otm';
import featureName from './config/feature.js';
import {AmateurTile} from './tiles/AmateurTile.js';
import './element/index.js';
import './attribute/index.js';

export default featureName;

angular
  .module(featureName, [angularTranslate])
  .config(provideTranslations(featureName))
  .config(provideTranslations('models'))
  .factory(Otm, OtmFactory)
  .factory('AmateurTile', () => AmateurTile)
  .component(onswAmateurContext, contextComponent)
  .run(runSedcardNav)
  .run(runLaforge);
