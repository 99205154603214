import angular from 'angular';

import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';
import {VERSION} from 'acng/core/service/env.js';

import {getLocale} from './model/locale.js';
import featureName from './config/feature.js';
import './attributes/index.js';

angular
  .module(featureName, [angularTranslate])
  .config(provideTranslations(featureName))
  .run([
    '$locale',
    'http',
    /**
     * @param {angular.ILocaleService} $locale
     * @param {core.Http} http
     */ async ($locale, http) => {
      const res = await http(true).get(`/locale/${getLocale()}?v=${VERSION}`);
      angular.copy(res.data, $locale);
    },
  ]);
