/**
 * Display the count of livecam senders who are streaming.
 *
 * The number is set as plain textContent to the {@link !Element},
 * or, if found in the subtree, `[.value]` textContent is set instead.
 *
 * @example
 * ```html
 * <span class="value" data-livecam-count></span>
 * ```
 *
 * ```html
 * <a href="#/livecams" data-livecam-count>
 *   Livecams
 *   <span class="value"></span>
 * </a>
 * ```
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.84.0
 *
 * @todo
 * - The problems that the asynchronous loading process brings with it are subject to refactoring.
 * - Do not use legacy Livecam factory.
 */

import {
  defineRegistryAttribute,
  defineCompileAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
import {removeNode, setText, sizeOfArray} from '@acng/frontend-bounty';
import {ctxCamEvent} from '../context/cam-event.js';
import {inject} from 'acng/core/service/ng.js';
import {Watch} from '@acng/frontend-relativity';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {livecamFeature} from '../config/feature.js';

const MODULE = 'livecam/attribute/data-livecam-count';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-livecam-count', (name) => {
  DEBUG: if (VERBOSE) console.debug(MODULE, 'setup', {name});

  defineCompileAttribute(name, (element) => {
    DEBUG: if (VERBOSE) element.setAttribute('debug', '');

    if (livecamFeature.isHidden) {
      DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, 'hidden feature', {name});
      removeNode(element);
    }
  });

  const watch = Watch(`[${name}]`, ctxCamEvent);

  defineRenderAttribute(name, (element) => {
    DEBUG: if (element.hasAttribute('debug')) console.debug(MODULE, 'connect', {element});

    watch(element, async (element) => {
      const count = sizeOfArray(await inject('Livecam').list());
      const target = query(element, '.value') ?? element;

      DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, {element, target, count});

      setText(target, `${count}`);
    });
  });
});
