/**
 * @module Login
 */

import {EVENT_SUBMIT, on, setTimeout} from '@acng/frontend-bounty';
import {debounce} from '@acng/frontend-bounty/timing/debounce.js';
import {IS, NULLABLE, OBJECT, OPTIONAL, STRING, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {popup} from '@acng/frontend-discovery';
import {defineRenderAttribute} from '@acng/frontend-stargazer';
import {iOS} from 'acng/core/constant/iOS';
import {post} from 'acng/core/service/backend';
import {inject} from 'acng/core/service/ng.js';
import {t} from 'acng/locale/config/translate';
import {AUTH_USER_DATA} from 'acng/userPool/service/typeguard.js';

const MODULE = 'userPool/config/controller/action-login';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRenderAttribute('data-action-login', (form) => {
  DEBUG: if (VERBOSE) console.info(MODULE, {form});
  ASSERT: typeguard(MODULE, form, IS(HTMLFormElement));

  const onsOverlay = inject('onsOverlay');
  const overlay = onsOverlay.get('pageLogin');

  const submit = debounce(
    async () => {
      const data = Object.fromEntries(new FormData(form));

      ASSERT: typeguard(
        `${MODULE} form-data`,
        data,
        OBJECT({
          login: STRING,
          password: STRING,
          remember: OPTIONAL(STRING),
        })
      );

      try {
        const authUserData = await post('user/login', data, form, async (error) => {
          switch (error.data.code) {
            case 1007:
              onsOverlay.create('confirm-device').open();
              return null;
            case 1009:
              popup(form).warn(await t('userPool.reactivationMailSent'));
              return null;
          }

          if (!onsOverlay.count()) {
            onsOverlay.create('pageLogin').disableBackdrop().open();
          }

          throw error;
        });

        ASSERT: typeguard(MODULE, authUserData, NULLABLE(AUTH_USER_DATA));

        if (authUserData) {
          inject('$rootScope').$broadcast('userPool.login.success', authUserData);
        }

        overlay?.close();
      } catch (reason) {
        // No further treatment is necessary.
      }
    },
    1000,
    true
  );

  DEBUG: if (VERBOSE) submit.enableDebug(MODULE);

  if (!iOS && overlay) {
    setTimeout(() => {
      ASSERT: typeguard(MODULE, form.login, IS(HTMLInputElement));

      form.login.focus();
    });
  }

  on(form, EVENT_SUBMIT, submit);
});
