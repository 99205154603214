import {Rendering, Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {allAnimationsFinished} from '@acng/frontend-bounty/animate.js';
import {disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {
  addClass,
  after,
  append,
  before,
  closestData,
  createDiv,
  createImage,
  createVideo,
  forEachOfArray,
  getAttribute,
  getDataAttribute,
  isInstanceOf,
  loadImage,
  pushToArray,
  removeClass,
  removeNode,
  setStyle,
  sizeOfArray,
  toggleClass,
} from '@acng/frontend-bounty';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';

import {amateurPoolFeature} from '../config/feature.js';
import {ctxAmateur} from '../context/amateur.js';
import {ctxOnline} from '../service/online.js';
import {fsk} from 'acng/userPool/fsk.js';
import {asset, media} from 'acng/core/service/env.js';
import dummySrc from 'assets/basic/img/f_320.png';
import {player} from 'acng/core/factory/flowplayer.js';
import {spinner} from 'acng/core/service/spinner.js';

const MODULE = 'amateurPool/widget/sedcard-image';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

(() => {
  const currentVjs = Symbol();
  const elementList = Symbol();

  const CSS_KISS_PREFIX = 'kissAnimation';
  const STYLE_WITHOUT_LIST = 'without-list';

  class SedcardImage extends Widget {
    /**
     * @type {?import('video.js').VideoJsPlayer}
     */
    [currentVjs] = null;

    /**
     * @type {Element[]}
     */
    [elementList] = [];

    link() {
      const widget = this;
      ASSERT: typeguard(MODULE, widget.nodes, NODES);
      const {backgroundElement, onlineElement, kissElement, pictureItem, movieItem} = widget.nodes;

      ctxAmateur[CTX_OBSERVE](widget, (amateur, previous) => {
        const elements = this[elementList];
        ctxOnline[CTX_OBSERVE](widget, (isOnline) => {
          setStyle(onlineElement, 'display', amateur && isOnline(amateur.id) ? null : 'none');
        });

        if (previous) {
          backgroundElement.src = '';
          removeNode(...elements);
          elements.length = 0;
          removeClass(kissElement, `${CSS_KISS_PREFIX}${previous.id}`);
        }

        if (amateur) {
          backgroundElement.src = amateur.getImageUrl('800');
          addClass(kissElement, `${CSS_KISS_PREFIX}${amateur.id}`);

          const list = getMediaList(amateur);

          toggleClass(kissElement, STYLE_WITHOUT_LIST, sizeOfArray(list) <= 1);

          forEachOfArray(list, (item) => {
            const template = item.type == 'movie' ? movieItem : pictureItem;
            const rendering = new Rendering(template);
            const fragment = rendering.toFragment(item);
            pushToArray(elements, ...fragment.children);
            before(pictureItem, fragment);
            rendering.connect();
          });

          spinner(setMedia(this, amateur.getImageUrl('1200'), 'background'), kissElement);
        }
      });
    }

    [disconnectedCallback]() {
      this[currentVjs]?.dispose();
      super[disconnectedCallback]();
    }

    /**
     * @param {Element} _element
     * @param {MouseEvent} ev
     */
    clickThumbnail(_element, {target}) {
      closestData(
        target,
        'src',
        (src, element) => {
          ASSERT: typeguard(MODULE, this.nodes, NODES);
          spinner(setMedia(this, src, getDataAttribute(element, 'type')), this.nodes.kissElement);
        },
        this
      );
    }
  }

  defineCustomWidget(amateurPoolFeature, 'onsw-sedcard-image', SedcardImage, [ctxAmateur, ctxOnline]);

  const setMedia = throttle(
    /**
     * @param {SedcardImage} widget
     * @param {string} src
     * @param {string | undefined} type
     */
    async (widget, src, type) => {
      ASSERT: typeguard(MODULE, widget.nodes, NODES);
      const {nodes} = widget;
      const {backgroundElement, imageElement} = nodes;
      const newBackground = createImage(
        isInstanceOf(imageElement, HTMLImageElement) ? imageElement.src : backgroundElement.src
      );

      /**
       * A new `Element` to be inserted.
       * @type {HTMLElement}
       */
      let element;

      /**
       * @type {?import('video.js').VideoJsPlayer}
       */
      let vjs = null;

      newBackground.className = backgroundElement.className;
      after(imageElement, newBackground);

      for (const listItem of widget[elementList]) {
        toggleClass(listItem, 'active', getAttribute(listItem, 'data-src') == src);
      }

      if (type == 'movie') {
        element = createDiv('video');
        const video = createVideo();
        append(element, video);
        vjs = await player(video, [{type: 'video/mp4', src}], true);
      } else {
        element = await loadImage(src, asset(dummySrc));
      }

      addClass(element, 'ons-image');
      after(newBackground, element);
      await allAnimationsFinished(element);
      backgroundElement.remove();
      imageElement.remove();
      nodes.backgroundElement = newBackground;
      nodes.imageElement = element;
      if (widget[currentVjs]) {
        widget[currentVjs].dispose();
      }
      widget[currentVjs] = vjs;
    }
  );

  DEBUG: if (VERBOSE) setMedia.enableDebug(MODULE);

  /**
   * @param {import('acng/amateurPool/factory/Amateur').Amateur} amateur
   */
  const getMediaList = (amateur) => {
    const getFsk = fsk.get();

    /**
     * @type {{type: "movie" | "image", src: string, previewSrc: string}[]}
     */
    const result = [];

    if (amateur.profile_video_path && (amateur.profile_video_fsk ?? 18) <= fsk.get()) {
      result.push({
        type: 'movie',
        previewSrc: amateur.getImageUrl('300'),
        src: `${media.content.movies}${amateur.profile_video_path}`,
      });
    }

    for (const [fsk, src] of amateur.new_images) {
      if (fsk <= getFsk) {
        result.push({
          type: 'image',
          previewSrc: `${media.content.pictures}${src}-300.jpg`,
          src: `${media.content.pictures}${src}-1200.jpg`,
        });
      }
    }

    return result;
  };
})();

const NODES = /* @__PURE__ */ (() =>
  OBJECT({
    kissElement: IS(HTMLElement),
    backgroundElement: IS(HTMLImageElement),
    imageElement: IS(HTMLElement),
    onlineElement: IS(HTMLElement),
    pictureItem: IS(HTMLTemplateElement),
    movieItem: IS(HTMLTemplateElement),
    listElement: IS(Element),
  }))();
