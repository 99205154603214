import {query, setTimeout} from '@acng/frontend-bounty';

angular.module('core').directive('onswToggleButton', ['$document', '$rootScope', function ($document, $rootScope) {
  return {
    scope: {
      label: '@',
      labelParams: '@',
      valueActive: '@',
      toggleValue: '=',
      locationClose: '@?',
      clickCloseExcept: '@?',
      onActive: '&',
      onInactive: '&'
    },
    template: '<span class="box" ng-attr-title="{{ label | translate:params }}" ng-click="toggle()"><span class="label" ng-bind="label | translate:params"></span></span>',
    link: function (scope, element) {
      element.addClass('ons-item');
      var box = element.children('.box');

      function isset() {
        return scope.toggleValue === scope.valueActive;
      }

      function set() {
        scope.valueInactive = scope.toggleValue;
        scope.toggleValue = scope.valueActive;
      }

      function reset() {
        scope.toggleValue = scope.valueInactive;
      }

      function clickCloseHandler(event) {
        if (!scope.clickCloseExcept) {
          return;
        }
        var el = scope.clickCloseExept === 'null' ? [] : angular.element(scope.clickCloseExcept);
        var keepOpen = false;
        for (var e = event.target; e; e = e.parentNode) {
          if (e === box[0]) keepOpen = true;
          el.each(function (key, val) {
            if (val === e) keepOpen = true;
          });
        }
        if (!keepOpen) {
          scope.$apply(reset);
        }
      }

      scope.toggle = function () {
        isset() ? reset() : set();
      };

      scope.$on('$destroy', function () {
        $document.off('click', clickCloseHandler);
      });

      let unregister = angular.noop;
      scope.$watch('toggleValue', function () {
        if (isset()) {
          if (scope.clickCloseExcept) {
            $document.on('click', clickCloseHandler);
            unregister = $rootScope.$on('closeToggleButtons', reset);
          }
          element.removeClass('inactive');
          element.addClass('active');
          scope.onActive();
        } else {
          $document.off('click', clickCloseHandler);
          unregister();
          unregister = angular.noop;
          element.removeClass('active');
          element.addClass('inactive');
          scope.onInactive();
        }
      });

      scope.$watch('labelParams', function (params) {
        scope.params = params ? angular.fromJson(params) : {};
      });

      if (scope.locationClose) {
        scope.$on('$locationChangeSuccess', function () {
          if (scope.toggleValue === scope.valueActive) {
            scope.toggleValue = scope.valueInactive;
          }
        });
      }
    }
  };
}]);
