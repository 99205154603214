import {body, isString} from '@acng/frontend-bounty';
import {createCacheMap} from '@acng/frontend-bounty/cache';
import {fetchJSON} from '@acng/frontend-bounty/fetch';
import {FeatureUtility, createFeature} from '@acng/frontend-stargazer';
import {VERSION} from 'acng/core/service/env.js';

const cache = createCacheMap();

//let locale = /** @type {string} */ (authUser?.preferred_language ?? startUpLanguage);
//body.lang = locale;

export const translateFeature = new FeatureUtility(
  createFeature('translate', ([featureName], insert) => {
    const src = `/language/${body.lang}/${featureName}?v=${VERSION}`;
    return cache(src, async () => {
      const translations = await fetchJSON(src);
      mapTranslationMarkup(translations);
      insert(translations);
    });
  })
);

const REGEXP = /{{\W*(\w*)\W*}}/g;

/**
 * @typedef {{[key: string]: string | TranslationData}} TranslationData
 */

/**
 * @deprecated
 * @param {TranslationData} translations
 */
const mapTranslationMarkup = (translations) => {
  for (const [key, item] of Object.entries(translations)) {
    if (isString(item)) {
      let result = '';
      let match;
      let lastIndex = 0;
      REGEXP.lastIndex = 0;
      while ((match = REGEXP.exec(item))) {
        if (match.index > lastIndex) {
          result += item.substring(lastIndex, match.index);
        }
        result += `\${${match[1]}}`;
        lastIndex = REGEXP.lastIndex;
      }
      if (lastIndex < item.length) {
        result += item.substring(lastIndex);
      }
      translations[key] = result;
    } else {
      mapTranslationMarkup(item);
    }
  }
};

/**
 * @param {string|string[]} src
 * @param {Record<string, string>} [params]
 * @returns {Promise<string>}
 */
export async function t(src, params) {
  if (isString(src)) {
    src = src.split('.');
  }
  return (await translateFeature.render(...src)).toText(params);
}
