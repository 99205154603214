/**
 * An `Element` with the `:responsive` attribute may have special formulated `class` entries.
 * Each entry will be added or removed according to the element´s width.
 *
 * @example
 * ```html
 * <div :responsive class="small-0-500 medium-500-1000 large-1000-"></div>
 * ```
 *
 * @module :responsive
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.83.0
 * @see [Element Variant](../widget/section.js)
 */

import {defineRenderAttribute} from '@acng/frontend-stargazer';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';

import {connect} from '../widget/section.js';

const MODULE = 'layout/attribute/responsive';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRenderAttribute(':responsive', (element) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, {element});
  ASSERT: typeguard(MODULE, element, IS(HTMLElement));

  connect(element);
});
