import {removeNode} from '@acng/frontend-bounty';
import {defineRenderAttribute} from '@acng/frontend-stargazer';
import {hasMyPortal} from 'acng/core/service/env';
import {authUser} from 'acng/userPool/context/auth-user';

defineRenderAttribute(':if-myportal', (element, value) => {
  if (value == 'not') {
    if (authUser && hasMyPortal) {
      removeNode(element);
    }
  } else {
    if (!authUser || !hasMyPortal) {
      removeNode(element);
    }
  }
});
