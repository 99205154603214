import {Watch} from '@acng/frontend-relativity';
import {Rendering, defineRegistryElement} from '@acng/frontend-stargazer';
import {
  HTMLElement,
  connectedCallback,
  defineElement,
  disconnectedCallback,
} from '@acng/frontend-bounty/dom/custom.js';
import {ctxAmateur} from '../context/amateur.js';
import {append, createElement, removeChildNodes, setText} from '@acng/frontend-bounty';
import {TAGNAME_TEMPLATE} from '@acng/frontend-bounty/dom/type.js';
import {inject} from 'acng/core/service/ng.js';

defineRegistryElement('onsw-amateur-label', (name) => {
  const watch = Watch(name, ctxAmateur);

  defineElement(
    name,
    class extends HTMLElement {
      #template = createElement(TAGNAME_TEMPLATE);

      [connectedCallback]() {
        append(this.#template.content, ...this.childNodes);
        const rendering = new Rendering(this.#template);
        watch(this, (element, amateur) => {
          if (!amateur) {
            removeChildNodes(element);
            return;
          }

          rendering.toElement(
            element,
            {
              nickname: amateur.getNickname(),
              age: amateur.age,
            },
            (nodes) => {
              const node = nodes.from;
              if (node) {
                setText(node, '');
                if (!inject('user').isInternational()) {
                  amateur.getLocation().then((from) => {
                    setText(node, from);
                  });
                }
              }
            }
          );
        });
      }

      [disconnectedCallback]() {
        this.replaceChildren(...this.#template.content.childNodes);
      }
    }
  );
});
