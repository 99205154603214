import {defineCustomWidget} from '@acng/frontend-stargazer';
import {InviteButton} from '../widgets/invite.js';
import {Feature} from 'acng/core/model/feature.js';

const name = 'games';
export const gamesFeature = new Feature(name);

defineCustomWidget(gamesFeature, 'onsw-game-invite', InviteButton);

['onsw-games', 'onsw-game', 'onsw-game-suggestions'].map((name) =>
  gamesFeature.defineRemoveWhenHidden(name)
);
