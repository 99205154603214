export const LAYOUT_CLASS_ITEM = 'layout-item';
export const LAYOUT_CLASS_WAIT = 'layout-wait';
export const LAYOUT_CLASS_DISABLED = 'layout-disabled';

/**
 * @deprecated
 */
export const LAYOUT_CLASS_ACTIVE = 'active';

/**
 * @deprecated
 */
export const LAYOUT_CLASS_INACTIVE = 'inactive';
