import './config/routes.js';
import './widget/operations.js';
import './widget/amateur-friends.js';
import './widget/user-friends.js';
import './attribute/index.js';

// The rest is required for angularjs
import angular from 'angular';
import name from './config/feature.js';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate.js';
angular.module(name, [angularTranslate]).config(provideTranslations(name));
