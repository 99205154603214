/**
 * @module [data-vip-points]
 */

import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {removeNode} from '@acng/frontend-bounty';

import {ctxVipPoints} from '../service/sock.js';
import {animate} from 'acng/layout/service/animate-number.js';
import {achievementFeature} from '../config/feature.js';

const MODULE = 'achievement/attribute/data-achievement-points';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-vip-points', (name) => {
  DEBUG: if (VERBOSE) console.debug(MODULE, 'setup', name);

  defineCompileAttribute(name, (element) => {
    DEBUG: if (VERBOSE) element.setAttribute('debug', '');

    if (achievementFeature.isHidden) {
      DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, 'hidden feature', {name});

      removeNode(element);
    }
  });

  const watch = Watch(`[${name}]`, ctxVipPoints);

  defineRenderAttribute(name, (element) => watch(element, animate));
});
