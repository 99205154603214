import {digest} from '@acng/frontend-stargazer';
import {rootRoute} from '@acng/frontend-voyager';
import {GlobalWatchGroup} from '@acng/frontend-relativity';
import {body, createDiv, removeChildNodes} from '@acng/frontend-bounty';
import {ctxAuthUser} from 'acng/userPool/context/auth-user.js';
import {ctxLocale} from 'acng/locale/model/locale.js';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';
import {hookFeature} from '../config/feature.js';

/**
 * @param {angular.IRootScopeService} $rootScope
 * @param {angular.ICompileService} $compile
 * @param {angular.ILocationService} $location
 */
export const runBootstrap = ($rootScope, $compile, $location) => {

  /**
   * @type {angular.IScope}
   */
  let $scope;

  const bootstrap = async () => {
    removeChildNodes(div);
    $scope?.$destroy();
    digest();
    rootRoute.reload($location.path());
    $scope = $rootScope.$new();
    $compile((await hookFeature.render('body')).toElement(div))($scope);
    $scope.$digest();
  };

  const div = createDiv();
  body.prepend(div);

  GlobalWatchGroup(ctxAuthUser, ctxLocale)(throttle(bootstrap, 10, false));
};
