/**
 * May be NOT wumper if value == previous
 */

import {Rendering} from '@acng/frontend-stargazer';
import {
  Text,
  allAnimationsFinished,
  append,
  createDiv,
  hide,
  isUndefined,
  removeNode,
  setText,
} from '@acng/frontend-bounty';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {TAGNAME_TEMPLATE} from '@acng/frontend-bounty/dom/type.js';
import {IS, OPTIONAL, typeguard} from '@acng/frontend-bounty/typeguard.js';

import {STYLE_EMPTY, swapClass, wumper} from './style.js';

const MODULE = 'layout/service/animate-number';

/**
 * @param {Element} element
 * @param {number} value
 * @param {number} [previous]
 */
export const animate = (element, value, previous) => {
  const valueNode = query(element, '.value') ?? element;
  const template = query(element, TAGNAME_TEMPLATE);
  const settle = () => {
    setText(valueNode, `${value}`);
    swapClass(element, STYLE_EMPTY, !value);
  };

  if (template) {
    hide(template);
  }

  if (isUndefined(previous) || isNaN(previous) || value < previous) {
    settle();
    return;
  }

  if (!template || value === previous) {
    settle();
    if (value) {
      wumper(element);
    }
    return;
  }

  new Rendering(template).toElement(createDiv(), {}, async (nodes, target) => {
    const {diff} = nodes;
    ASSERT: typeguard(MODULE, diff, OPTIONAL(IS(Text)));

    if (diff) {
      setText(diff, `${value - previous}`);
    }
    append(element, target);

    await allAnimationsFinished(target);
    removeNode(target);

    // TODO Not 100% safe
    if (previous == Number(valueNode.textContent)) {
      settle();
      wumper(element);
    }
  });
};
