import {GlobalWatch, createGlobalContext} from '@acng/frontend-relativity';
import {get} from 'acng/core/service/backend.js';
import {ARRAY, BOOLEAN, ENUM, INTEGER, OBJECT, STRING, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {ctxEventBusUser, listen} from './event-bus-user.js';
import {TIMESTAMP_MS} from 'acng/core/service/typeguard.js';
import {STATE_CONFIRMED, STATE_REQUESTED} from 'acng/friend/service/friendship.js';
import {authUser} from './auth-user.js';
import {pushToArray} from '@acng/frontend-bounty';
import {clearPins, setPins} from 'acng/pin/service.js';

const MODULE = 'userPool/context/ignored-by';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @type {Set<string>}
 */
export const ignoredBy = new Set();

export const ctxIgnoredBy = createGlobalContext(ignoredBy);

(() => {
  GlobalWatch(ctxEventBusUser)(async (user) => {
    ignoredBy.clear();
    clearPins();

    if (!user) {
      return;
    }

    const data = await get('user/relations');

    ASSERT: typeguard(MODULE, data, RELATIONSHIPS);
    DEBUG: if (VERBOSE) console.debug(MODULE, data);

    if (user.id != authUser?.id) {
      return;
    }

    /**
     * @type {string[]}
     */
    const pins = [];

    for (const relation of data) {
      switch (relation.status) {
        case STATE_IGNORED:
          DEBUG: if (VERBOSE) console.info(MODULE, STATE_IGNORED, relation);
          ignoredBy.add(relation.user_id);
          break;
        case STATE_FAVORITE:
          DEBUG: if (VERBOSE) console.info(MODULE, STATE_FAVORITE, relation);
          pushToArray(pins, relation.user_id.split('-')[1]);
          break;
        default:
      }
    }

    setPins(pins);

    ctxIgnoredBy.provide(null, ignoredBy);
  });

  listen('ignore', (data) => {
    ASSERT: typeguard(MODULE, data, EVENTBUS_IGNORE);
    const partnerUid = `${data.partner.pool_id}-${data.partner.id}`;

    DEBUG: if (VERBOSE) console.info(MODULE, data);

    ignoredBy[data.status ? 'add' : 'delete'](partnerUid);

    ctxIgnoredBy.provide(null, ignoredBy);
  });
})();

const STATE_FAVORITE = 'favorite';
const STATE_IGNORED = 'ignored_by';

const ALOHA_STATUS = /** @type {const} */ ([STATE_FAVORITE, STATE_IGNORED, STATE_REQUESTED, STATE_CONFIRMED]);

const EVENTBUS_IGNORE = /* @__PURE__ */ (() =>
  OBJECT({
    type: ENUM(/** @type {const} */ (['ignore'])),
    partner: OBJECT({pool_id: INTEGER, id: STRING}),
    status: BOOLEAN,
    timestamp_ms: TIMESTAMP_MS(),
  }))();

const RELATIONSHIPS = /* @__PURE__ */ (() =>
  ARRAY(
    OBJECT({
      user_id: STRING,
      nickname: STRING,
      date: STRING,
      status: ENUM(ALOHA_STATUS),
    })
  ))();
