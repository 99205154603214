/**
 * @module
 * @todo
 * - Export omit(ctxAuthUser, CTX_PROVIDE) when omit is available in bounty.
 */

import {createGlobalContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';
import {authUser as authUserData} from 'acng/core/service/env.js';
import {guestFeature, userFeature} from '../config/feature.js';

export let authUser = authUserData;

export const ctxAuthUser = createGlobalContext(authUser);

/**
 * @param {?import('@acng/frontend-bounty/typeguard.js').Asserted<import('../service/typeguard').AUTH_USER_DATA>} user
 */
export const provideAuthUser = (user) => {
  authUser = user;
  userFeature.hidden = !authUser;
  guestFeature.hidden = !!authUser;
  ctxAuthUser[CTX_PROVIDE](null, authUser);
};

userFeature.hidden = !authUser;
guestFeature.hidden = !!authUser;
