/**
 * Elements matching `[data-click-dialogs]` wi
 *
 * @example
 * ```html
 * <span data-click-dialogs> Dialogs </span>
 * ```
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.84.0
 */

import {onClick} from '@acng/frontend-bounty';
import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {pushHistory} from '@acng/frontend-relativity/history';

import {getContactsVisibility, setContactsVisibility} from '../context/contacts-visibility.js';
import {getGlobalDialog, setGlobalDialog} from '../context/global-dialog.js';
import {ngDigest} from 'acng/core/service/ng.js';

defineRegistryAttribute('data-click-dialogs', (name) => {
  const showContacts = () => {
    if (!getContactsVisibility() || getGlobalDialog()) {
      setGlobalDialog(null);
      setContactsVisibility(true);
    } else {
      pushHistory();
      setContactsVisibility(false);
    }
    ngDigest();
  };

  defineRenderAttribute(name, (element) => onClick(element, showContacts));
});
